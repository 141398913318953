@import "variables";
@import "themes/theme-variables";
@import "themes/theme-mixins";

.tl-dialog-title-wrapper {
  h3 span {
    margin-right: 5px;
  }

  .btn-icon {
    margin-right: 0;

    &.btn-icon-favorite.active {
      color: theme-var($--tl-theme-warn);
    }

    &.btn-icon-pinned.active {
      color: theme-var($--tl-theme-danger);

      svg * {
        fill: theme-var($--tl-theme-danger);
      }
    }
  }
}

.mat-mdc-dialog-actions {
  .btn.btn-secondary {
    background-color: theme-var($--tl-theme-dark-surface);
    border-color: theme-var($--tl-theme-dark-surface);
    color: theme-var($--tl-theme-dark-text);
    cursor: pointer;
    font: theme-var($--tl-theme-font-caption);
    padding: 0.35rem 0.75rem 0.35rem 0.6rem;
    text-transform: uppercase;
  }
}

tl-filter-conjunction {
  width: 100%;
}

.tl-filters-pages .filter-options {
  margin: 0;
  padding: 0;
  &.two-columns{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 0px;
  }

  label {
    cursor: pointer;
    display: block;
    width: 100%;
  }

  .filter-option {
    list-style: none;
    padding: 10px 18px;
    margin: 9px 0;
    border-radius: 5px;
    border: 2px solid theme-var($--tl-theme-background);

    &.filter-option--single-select,
    &.filter-option--multi-select {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    &:hover {
      background-color: theme-var($--tl-theme-background);
    }

    .mat-mdc-checkbox,
    .mat-mdc-radio-button {
      width: calc( 100% + 10px ); /* we have -ve margin so add that back in the width */
    }

    .mdc-form-field {
      width: 100%;
    }
  }

  .custom-range-filter-option {
    label {
      display: flex;
      gap: 10px;
      color: var(--tl-theme-text);
      margin: 0;
      padding: 0;
      -webkit-font-smoothing: antialiased;
    }

    i, fa-icon {
      color: theme-var($--tl-theme-primary);
      margin: 0 7px 0 5px;
    }
  }
}
