@import './variables';
@import './mixins';
@import 'themes/theme-mixins';
@import 'themes/theme-variables';

html {
  font-size: 16px; /* This determines rem size */
}

body, html {
  height: 100%;
}

body {
  background-color: theme-var($--tl-theme-background);
  padding-top: 50px;
  color: theme-var($--tl-theme-text);
  font: theme-var($--tl-theme-font-body);
  text-align: left;
}

// font sizes
h1 {
  font: theme-var($--tl-theme-font-h1);
  margin-bottom: 1rem;
}

h2 {
  font: theme-var($--tl-theme-font-h2);
  margin-bottom: 2rem;
}

h3 {
  font: theme-var($--tl-theme-font-h3);
}

h4 {
  font: theme-var($--tl-theme-font-h4);
}

h5 {
  font: theme-var($--tl-theme-font-h5);
}

h6 {
  font: theme-var($--tl-theme-font-h6);
}

label {
  color: inherit;
  font: theme-var($--tl-theme-font-label);

  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 0.5rem;
}

small {
  font: theme-var($--tl-theme-font-body3);
  color: theme-var($--tl-theme-text-light);
}

a {
  color: theme-var($--tl-theme-link);
  text-decoration: none;

  &:hover {
    color: theme-lightness($--tl-theme-link, -10%);
  }
}

.emoji {
  display: inline-block;
}

::-ms-clear {
  display: none;
}

.simple-list {
  padding: 0;
  list-style: none;
  border-top: $border-accent10;

  li {
    padding: 10px 0 10px 10px;
    margin: 0;
    border-bottom: $border-accent10;
  }
}

button:active {
  outline: none !important;
  box-shadow: none !important;
}

.btn[disabled] {
  cursor: not-allowed !important;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0
}

// .page {
//   padding: 20px;
// }

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  @include placeholder-style;
}
::-moz-placeholder { /* Firefox 19+ */
  @include placeholder-style;
}
:-ms-input-placeholder { /* IE 10+ */
  @include placeholder-style;
}
:-moz-placeholder { /* Firefox 18- */
  @include placeholder-style;
}

.custom-radio {
  cursor: pointer;
}

.btn-link {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  color: theme-var($--tl-theme-link);
  font: theme-var($--tl-theme-font-body);

  /* text followed by icon */
  span + i, strong + i {
    margin-left: 8px;
  }
}

.btn-link-primary {
  color: theme-var($--tl-theme-primary);
  font: theme-var($--tl-theme-font-body2);

  &:hover, &:active, &:focus {
    color: theme-var($--tl-theme-primary);
  }
}

.subtle-link {
  color: theme-var($--tl-theme-text);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: theme-var($--tl-theme-text);
  }
}

.btn-icon {
  background: none;
  border: 0;
  outline: 0;
  color: theme-var($--tl-theme-text);
  height: 24px;
  width: 24px;
  padding: 0;

  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  margin: 0 5px;
  cursor: pointer;

  &.btn {
    padding: 0;
  }

  svg * {
    fill: theme-var($--tl-theme-text);
  }

  // need to be white color for dark background
  &.btn-icon--dark {
    svg * {
      fill: theme-var($--tl-theme-surface);
    }
  }
}

.tl-text-info {
  color: theme-var($--tl-theme-text-light);
}

/* fa-icon[tooltip] only works for static help tooltip. For dymamic help, add the class "tl-help-icon" */
.tl-help-icon,
fa-icon[tooltip] .fa-circle-info {
  color: theme-var($--tl-theme-info);
  font-size: 1rem;
}

tl-button-loader {
  display: inline-block;
}

body.teamsInMeetingApp {
  background-color: theme-var($--tl-theme-dark-surface);
  padding-top: 0;

  .isTeamsContent {
    margin-bottom: 0;
  }

  .loading-app .cover {
    background: theme-var($--tl-theme-dark-surface);
  }

  svg .text-path {
    fill: theme-var($--tl-theme-dark-text);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  10%, 20% {
    -webkit-transform: scale(.9) rotate(-8deg);
    transform: scale(.9) rotate(-8deg);
  }

  30%, 50%, 70% {
    -webkit-transform: scale(1.3) rotate(8deg);
    transform: scale(1.3) rotate(8deg);
  }

  40%, 60% {
    -webkit-transform: scale(1.3) rotate(-8deg);
    transform: scale(1.3) rotate(-8deg);
  }

  80%, 100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

.faa-tada.animated,
.faa-tada.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-tada {
  -webkit-animation: tada 2s linear infinite;
  animation: tada 2s linear infinite;
}

.faa-tada.animated.faa-fast,
.faa-tada.animated-hover.faa-fast:hover,
.faa-parent.animated-hover:hover > .faa-tada.faa-fast {
  -webkit-animation: tada 1s linear infinite;
  animation: tada 1s linear infinite;
}

.faa-tada.animated.faa-slow,
.faa-tada.animated-hover.faa-slow:hover,
.faa-parent.animated-hover:hover > .faa-tada.faa-slow {
  -webkit-animation: tada 3s linear infinite;
  animation: tada 3s linear infinite;
}

.live-updates-indicator {
  background-color: theme-lightness($--tl-theme-danger, 20%);
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
  width: 7px;
  height: 7px;

  &.connected {
    animation: live 2s linear infinite;
    background-color: theme-var($--tl-theme-primary);
  }
}

.results--empty-message {
  background: theme-var($--tl-theme-accent10);
  font-style: italic;
  padding-top: 2px;
  padding-bottom: 5px;
  text-align: center;
}

mat-form-field.mat-focused {
  outline: 2px solid theme-var($--tl-theme-secondary);
}

.btn.button-view {
  @include management-action-button;
}

// zoom theme
html[data-theme="zoomlight"] {
  body {
    background-color: theme-var($--tl-theme-surface);
  }

  body.tl-iframe {
    padding-top: 78px; /* brand below the nav only in iframe */

    tl-app-root {
      display: block;
    }
  }

  mat-form-field.mat-focused {
    outline-color: theme-var($--tl-theme-primary);
  }
}

.redacted-text {
  cursor: pointer;
  color: transparent; // text should not be visible
  user-select: none; // text should not be selectable
  background: theme-var($--tl-theme-dark-surface); // background should be blacked out
  font-size: 80%; // decrease height of black redaction boxes
  letter-spacing: 1.5px; // restore width of redaction at font-size: 100%
}

.toast-container {
  z-index: 999999;
  position: fixed;
  padding-right: 20px;
  .ngx-toastr {
    padding-right: 20px;
  }
}

// This overrides some styles in the <ng-select> component. Right now, we're just scoping it to the
// <tl-filter-options-dialog> and <tl-create-group-dialog> component, but if we want to make it global, just remove this outer
// class
.tl-filter-options-dialog, .tl-create-group-dialog {
  // This overrides the default style for options in an ng-select dropdown
  // Must beat a selector weight of 0,3,0
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    // This allows long entries to break to a newline, rather than being cut off at the end
    white-space: normal;
  }

  // This overrides the default style for the items in the input of an ng-select-multiple
  // must beat a selector weight of 0,5,0
  ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    // allow text to break lines
    white-space: normal;
    // using flex so that all children will
    display: flex;
  }

  // This overrides the default style for the "X" icon of items in the input of an ng-select-multiple
  // must beat a selector weight of 0,6,0
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: flex;
    // This will allow the "X" to align to the center when the value of the option has a line break.
    // By setting this on the value icon rather than the whole value, it will allow the border to remain
    // full height.
    align-items: center;
  }
}

// Target Firefox only. Fixes PORT-8012. Issue with position: sticky.
@supports (-moz-appearance:none) {
  .cdk-global-scrollblock.participants-full-list-open {
    overflow: hidden;
    position: static;
  }
}

.status-indicator {
  border-radius: 100%;
  display: inline-block;
  height: 8px;
  margin-right: 2px;
  width: 8px;

  &.status-indicator--grey,
  &.CLOSED {
    background-color: theme-var($--tl-theme-dark-surface);
  }

  &.status-indicator--green,
  &.OPEN {
    background-color: theme-var($--tl-theme-primary);
  }

  &.status-indicator--yellow {
    background-color: theme-var($--tl-theme-warn);
  }

  &.status-indicator--red {
    background-color: theme-var($--tl-theme-danger);
  }
}

.datum-id-tag {
  background-color: theme-var($--tl-theme-accent10);
  padding: 0 6px;
  border-radius: 4px;
  margin-left: 5px;
  display: inline-block;
  word-break: normal;
}

.review-details-dialog .datum-id-tag {
  margin: 0;
}

.load-more-spinner {
  display: block;
  margin-top: -.45rem;
  margin-bottom: -.45rem;
}

/* Progress Bar Overwrite */
.mat-mdc-progress-bar {
  .mdc-linear-progress__buffer-bar {
    background-color: theme-alpha($--tl-theme-primary, 25%);
  }

  .mdc-linear-progress__bar-inner {
    border-color: theme-var($--tl-theme-primary);
  }
  &.mat-progress-bar-disabled {
    .mdc-linear-progress__buffer-bar {
      background-color: theme-alpha($--tl-theme-text-light, 25%);
    }
    .mdc-linear-progress__bar-inner {
      border-color: theme-var($--tl-theme-text-light);
    }
  }
}

//moved this to here as it was being leaked globally from policy-management.component.scss
header h2 {
  line-height: 1.4rem;
  margin-bottom: 0.5rem;

  small {
    display: inline-block;
    padding-top: 10px;
  }
}

mark.tl-search-highlight {
  background-color: theme-var($--tl-theme-search-highlight);
  padding: 0;
}

ng2-pdfjs-viewer {
  iframe {
    border: 0;
    .pdfViewer .page{
      border-left-width: 0;
      border-right-width: 0;
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.label-description-col {
  max-width: 100%;
  width: 100%;
  overflow: hidden;

  .datum-label-description {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}