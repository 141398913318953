@import 'variables';

@mixin tooltipDark {
  background-color: theme-var($--tl-theme-dark-surface);
  color: theme-var($--tl-theme-dark-text);
  font: theme-var($--tl-theme-font-body3);
  font-weight: normal;
  max-width: 240px;
  border-radius: 6px;
  text-align: left;
  padding: 5px 8px;
}

@mixin tooltipLight {
  @include tooltipDark();

  background-color: theme-var($--tl-theme-surface);
  color: theme-var($--tl-theme-text);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.tooltip {
  z-index: 2100 !important; // tooltips must be visible for modals

  &.bs-tooltip-top,
  &.bs-tooltip-auto[data-popper-placement^=top] {
    .tooltip-arrow {
      // bottom: 0;

      &:before {
        // bottom: 0;
        // border-width: .4rem .4rem 0;
        border-top-color: theme-var($--tl-theme-dark-surface);
      }
    }
  }

  &.bs-tooltip-end,
  &.bs-tooltip-auto[data-popper-placement^=right] {
    .tooltip-arrow:before {
      border-right-color: theme-var($--tl-theme-dark-surface);
    }
  }

  &.bs-tooltip-bottom,
  &.bs-tooltip-auto[data-popper-placement^=bottom] {
    .tooltip-arrow:before {
      // bottom: 0;
      // border-width: 0 .4rem .4rem;
      border-bottom-color: theme-var($--tl-theme-dark-surface);
    }
  }

  &.bs-tooltip-start,
  &.bs-tooltip-auto[data-popper-placement^=start] {
    .tooltip-arrow:before {
      border-left-color: theme-var($--tl-theme-dark-surface);
    }
  }

  .tooltip-inner {
    @include tooltipDark;

    .tooltip-title {
      font: theme-var($--tl-theme-font-h4);
      padding-bottom: 20px;
      color: theme-var($--tl-theme-primary);
      text-transform: uppercase;
    }

    .tooltip-title--simple {
      color: theme-var($--tl-theme-text);
      text-transform: capitalize;
    }

    ul {
      padding-left: 16px;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
    }

    label {
      font: inherit;
      margin-bottom: 0;
    }

    p {
      margin-bottom: .25rem;
    }

    table {
      width: 100%;
    }

    span.value {
      color: theme-var($--tl-theme-dark-text);
    }
  }

  &.chart {
    &.bs-tooltip-bottom,
    &.bs-tooltip-auto[data-popper-placement^=bottom] {
      .tooltip-arrow:before {
        border-bottom-color: theme-var($--tl-theme-accent30);
      }
    }

    .tooltip-inner {
      @include tooltipLight();
      padding: 0;

      table {
        margin-bottom: 0;
      }
    }
  }

  &.tooltip-white {
    &.bs-tooltip-top,
    &.bs-tooltip-auto[data-popper-placement^=top] {
      .tooltip-arrow {
        &:before {
          border-top-color: theme-var($--tl-theme-surface);
        }
      }
    }

    &.bs-tooltip-end,
    &.bs-tooltip-auto[data-popper-placement^=right] {
      .tooltip-arrow:before {
        border-right-color: theme-var($--tl-theme-surface);
      }
    }

    &.bs-tooltip-bottom,
    &.bs-tooltip-auto[data-popper-placement^=bottom] {
      .tooltip-arrow:before {
        border-bottom-color: theme-var($--tl-theme-surface);
      }
    }

    &.bs-tooltip-start,
    &.bs-tooltip-auto[data-popper-placement^=start] {
      .tooltip-arrow:before {
        border-left-color: theme-var($--tl-theme-surface);
      }
    }

    .tooltip-inner {
      @include tooltipLight();

      .tooltip-title {
        font: theme-var($--tl-theme-font-h4);
        padding-bottom: 20px;
        color: theme-var($--tl-theme-primary);
        text-transform: uppercase;
      }

      .tooltip-title--simple {
        color: theme-var($--tl-theme-text);
        text-transform: capitalize;
      }

      .tooltip-section {
        margin-top: 5px;
      }

      .tooltip-link:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      p {
        word-break: normal;
        margin: 5px 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &.tooltip-integration-policy-issues {
      padding: 0;

      .tooltip-inner {
        padding: 0;
      }

      p {
        font: theme-var($--tl-theme-font-body2);
      }
    }
  }

  &.tooltip--md {
    padding: 0;

    .tooltip-inner {
      max-width: 280px;
    }
  }

  &.tooltip--lg {
    padding: 0;

    .tooltip-inner {
      padding: 10px 16px;
      max-width: 300px;
      min-width: 280px;
    }
  }

  &.tooltip--xlg {
    padding: 0;

    .tooltip-inner {
      padding: 0;
      max-width: 420px;
    }
  }

  &.tooltip--xxl {
    .tooltip-inner {
      padding: 10px 16px;
      max-width: 560px;
    }
  }

  &.tl-participant-details-popup {
    min-width: 180px;
  }

  &.tooltip__media-info,
  &.media-item__archive-info__tooltip {
    &.bs-tooltip-bottom-end,
    &.bs-tooltip-top-end {
      .tooltip-inner {
        margin-right: -20px;
      }
    }

    &.bs-tooltip-bottom-start,
    &.bs-tooltip-top-start {
      .tooltip-inner {
        margin-left: -20px;
      }
    }
  }

  // Bootstrap doesn't position the tooltip correctly when
  // 1. Tooltip container is body
  // 2. One of the parent of the tooltip trigger is 'fixed' position (such as risk score tooltip in review detail dialog)
  // 3. Below content is scrolled down (such as dialog is on top review board which is scrolled to the end)
  // Following is added to fixed the tooltip position.
  &.tooltip--review-details-risk-score {
    position: fixed !important;
    transform: translate(-111px, 62px) !important;
  }

  .scrollable-content, .scrollable-content--vertical {
    max-height: 300px;
    padding: 10px 16px;
  }

  .scrollable-content {
    overflow: auto;
    white-space: nowrap;
  }

  .scrollable-content--vertical {
    overflow-y: auto;
  }
}

// Chart tooltip with table
.bb-tooltip {
  th {
    background-color: theme-var($--tl-theme-accent30);
  }

  th > span {
    border-radius: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }

  td {
    color: theme-var($--tl-theme-text);

    > span {
      border-radius: 50%;
    }
  }
}

// prevents tooltips from being positioned relative to the body when dialogs are open
.cdk-global-scrollblock .ng-tooltip {
  position: fixed;
}

// used in audit logs
.tooltip-table {
  margin-bottom: 0;

  tr {
    border-bottom: $border-accent10;

    &:last-child {
      border-bottom: 0;

      td {
        border-bottom: 0;
      }
    }
  }

  td {
    vertical-align: top;
    padding: 5px 0 !important;

    &:nth-child(1) {
      white-space: nowrap;

      span {
        display: block;
        padding-right: 10px;
      }
    }

    &:nth-child(2) {
      white-space: nowrap;
      padding-right: 10px !important;
    }
  }
}

//avatar-identity tooltip
.tl-identity-details {
  p {
    margin-bottom: 0;
    word-break: break-all;
  }
}
