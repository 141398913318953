@import 'variables';
@import './themes/theme-variables';
@import './themes/theme-mixins';

/* a wrapper element that tracks the mouse vertically */
.vjs-thumbnail-holder {
  left: -1000px;
  display: none;
  position: absolute;
}

/* the thumbnail image itself */
.vjs-thumbnail {
  bottom: 1.3em;
  left: 0;
  opacity: 0;
  position: absolute;
  -moz-transition: opacity .2s ease;
  -mz-transition: opacity .2s ease;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
}

/* fade in the thumbnail when hovering over the progress bar */
/* .fake-active is needed for Android only. It's removed on touchend/touchecancel */
.vjs-progress-control:hover .vjs-thumbnail,
.vjs-progress-control.fake-active .vjs-thumbnail,
.vjs-progress-control:active .vjs-thumbnail {
  opacity: 1;
}

/* ... but hide the thumbnail when hovering directly over it */
.vjs-progress-control:hover .vjs-thumbnail:hover,
.vjs-progress-control:active .vjs-thumbnail:active {
  opacity: 0;
}

.video-js {
  &.vjs-settings-menu {
    display: block;
  }
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 20em;
}

.video-js.vjs-fullscreen .vjs-control-bar {
  bottom: 0;
}

.vjs-playback-rate .vjs-playback-rate-value {
  font-size: theme-var($--tl-theme-font-body3);
  line-height: 1.875rem;
}

.video-js .vjs-time-control {
  line-height: 2rem;
}

.vjs-icon-cog:before {
  font-size: 1rem;
}

.video-js .vjs-jump-to-start-btn .vjs-icon-placeholder,
.video-js .vjs-jump-to-end-btn .vjs-icon-placeholder,
.video-js .vjs-loop-btn .vjs-icon-placeholder {
  font-family: 'Material Icons';
}

.video-js .vjs-jump-to-start-btn .vjs-icon-placeholder:before {
  content: "\e045";
}

.video-js .vjs-jump-to-end-btn .vjs-icon-placeholder:before {
  content: "\e044";
}

.video-js .vjs-loop-btn .vjs-icon-placeholder:before {
  content: "\e040";
}

.video-js .vjs-loop-btn.vjs-loop-btn--active .vjs-icon-placeholder:before {
  content: "\e9d6";
}

.vjs-loop-control {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;

  .vjs-loop-range-selector {
    width: 100%;
  }

  .range-slider {
    height: 20px;
    text-align: center;
    position: relative;
    top: 10px;
  }

  /********** Range Input Styles **********/
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    left: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
  }

  /* Removes default focus */
  input[type="range"]:focus {
    border-color: transparent;
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: transparent;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -10px; /* Centers thumb on the track */
    z-index: 2;
    position: relative;
    /*custom styles*/
    background-color: theme-var($--tl-theme-primary);
    height: 30px;
    width: 5px;
  }

  /******** Firefox styles ********/
  /* slider track */
  input[type="range"]::-moz-range-track {
    background-color: transparent;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    z-index: 2;
    position: relative;
    /*custom styles*/
    background-color: theme-var($--tl-theme-primary);
    height: 30px;
    width: 5px;
  }

  input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid theme-var($--tl-theme-primary);;
    outline: 3px solid theme-var($--tl-theme-primary);;
    outline-offset: 0.125rem;
  }
}

// RESPONSIVE
// https://videojs.com/guides/layout/#responsive-mode

// for x-small and small player, hide seek buttons
.vjs-layout-x-small,
.vjs-layout-small {
  .vjs-seek-button {
    display: none;
  }
}

// for medium player, hide seek buttons based on breakpoint
.vjs-layout-medium {
  .vjs-seek-button {
    // hide seek buttons below lg breakpoint by default
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}


.vjs-layout-medium.vjs-is-main-content {
  // when player has the vjs-is-main-content class we show seek buttons by default.
  .vjs-seek-button {
    display: block;
    // and hide them if viewport is less than md
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}


.video-js {
  margin-bottom: 30px;
}

.vjs-poster {
  background-color: theme-var($--tl-theme-dark-surface);
}

.is-video .vjs-poster {
  background-size: cover;
}

.video-loading .video-js .vjs-big-play-button,
.video-loading .video-js .vjs-control-bar {
  display: none;
}

.video-js .vjs-control-bar {
  background-color: theme-var($--tl-theme-dark-surface);
  bottom: -30px;
  display: flex;
  opacity: 1;
  position: absolute;
  transition: visibility 0.1s, opacity 0.1s;
  visibility: visible;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  visibility: visible;
  opacity: 1;
}

.video-js .vjs-current-time {
  display: block;
}

.video-js .vjs-volume-panel {
  width: 9em;
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
  height: 3em;
  width: 5em;
}

.vjs-menu li .vjs-menu-item-text {
  font: theme-var($--tl-theme-font-body3);
  line-height: 22px;
}

.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}

.vjs-paused.vjs-has-started.vjs-waiting .vjs-big-play-button,
.vjs-paused.vjs-has-started.vjs-seeking .vjs-big-play-button {
  display: none;
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0;
}

.video-js .vjs-thumbnail {
  bottom: 15px;
}

.video-js .vjs-time-tooltip {
  border-radius: 0;
  padding: 6px 8px;
  top: -35px;
}

/* --------------------------------------------------------------------- */
/* MARKERS
/* --------------------------------------------------------------------- */

.vjs-marker-comment {
  left: -3px;
  position: absolute;
  top: -19px;
}

.vjs-marker.marker-scene:before {
  background: theme-var($--tl-theme-surface);
  content: "";
  display: inline-block;
  height: 15px;
  left: -1px;
  position: absolute;
  top: -6px;
  width: 3px;
}

.vjs-marker.marker-search {
  z-index: 200;

  &:before {
    @include search-marker('/../../assets/images');
  }
}

.vjs-marker.marker-search-single:before {
  @include search-marker('/../../assets/images');
}

.vjs-marker.marker-policy:before {
  background-image: url(../assets/images/policy-marker.png);
  background-size: 16px 16px;
  content: "";
  display: inline-block;
  height: 16px;
  left: -6px;
  position: absolute;
  top: 5px;
  width: 16px;
}

.vjs-marker-counter {
  left: 1px;
  top: -16px;
}

.vjs-marker.marker-offsets {
  border-radius: 0 !important;
  bottom: -1px;
  height: 5px;
  z-index: 0;

  &.marker-face {
    background-color: theme-var($--tl-theme-primary) !important;
  }

  &.marker-silence {
    background-color: theme-var($--tl-theme-quaternary) !important;
  }

  &.marker-script-difference {
    background-color: theme-var($--tl-theme-danger) !important;
  }
}

.vjs-marker.marker-offsets:hover {
  transform: none;
}

.video-js .vjs-progress-holder {
  transition: none;
}

.video-js .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 1em;
  height: 0.5em;
}

.video-js .vjs-progress-control:hover .vjs-marker.marker-offsets {
  bottom: 0;
}

.video-js .vjs-play-progress:before {
  z-index: 1000;
}

.video-js .vjs-progress-control:hover .vjs-play-progress:before {
  font-size: 1.2em;
}

.video-js .vjs-progress-control:hover .vjs-time-tooltip,
.video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip {
  font-size: 1em;
}


.vjs-marker-toggle-menu-item.vjs-menu-item {
  padding: 2px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: none;

  .vjs-menu-item-text {
    width: 60px;
    text-align: left;
  }
}

.vjs-menu {
  .vjs-marker-check-icon-placeholder {
    width: 12px;
    height: 12px;
  }

  .vjs-marker-check-icon {
    display: none;
  }
}

.vjs-menu li.vjs-selected.vjs-marker-toggle-menu-item {
  background-color: transparent;
  color: inherit;

  .vjs-marker-check-icon {
    display: inline-block;
  }
}

.vjs-menu .vjs-menu-content li.vjs-menu-item.vjs-marker-toggle-menu-item.vjs-selected:focus,
.vjs-menu .vjs-menu-content li.vjs-menu-item.vjs-marker-toggle-menu-item.vjs-selected:hover {
  background-color: theme-var($--tl-theme-accent20) !important;
  color: inherit !important;
}

.vjs-markers-setting-button .vjs-menu {
  width: 11em;
}

.vjs-marker-toggle-menu-item-disabled {
  color: theme-var($--tl-theme-text-light);
}

.waveform-only {
  &.is-audio {
    .video-js.video-player {
      padding-top: 0;
      height: auto;
    }

    .vjs-big-play-button, .vjs-paused.vjs-has-started .vjs-big-play-button {
      display: none;
    }

    .vjs-control.waveform {
      top: 0;
      z-index: 0;
      height: 50px;
    }

    .vjs-volume-panel.vjs-control.vjs-volume-panel-vertical, .vjs-menu {
      z-index: 1;
    }

    .vjs-playback-rate {
      .vjs-menu {
        width: 200px;
        margin-left: -100px;
        ul{
          columns: 3;
          overflow: hidden;
        }
      }
      &.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
        max-height: 90px;
        background-color: #2b333f;
      }
      li.vjs-selected{
        background-color: grey;
        color: black;
      }
    }

    .vjs-markers-setting-button {
      &.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
        max-height: 90px;
        background-color: #2b333f;
      }
      li.vjs-selected.vjs-marker-toggle-menu-item{
        background-color: grey;
        color: black;
      }
    }

    // for error dialog
    .video-js .vjs-modal-dialog {
      height: 50px;

      .vjs-modal-dialog-content {
        background-color: black;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
